import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  IconAndTextColor: Color.Primitive.PrimaryContrast,
  Background: Color.Primitive.Primary,

  RightCornerBackground: Color.Surface.Base.Foreground,
  RightCornerIconAndTextColor: Color.Surface.Base.Background,
};
